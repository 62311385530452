import React, { Component } from 'react';
import { Modal, Form, Input, Radio, Select, TextArea, Button, Dropdown } from 'semantic-ui-react';
import { ajax } from 'rxjs/ajax';
import Alert from '../Alert';
import { isEmpty, toPairs, head, last } from 'ramda';
import { API_URL } from '../../constants/credentials';

class FuelPartnerModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stations: [],
            editedPartner: null,
            isFetching: false,
            alert : null,
            error : null
        }
        this.setField = this.setField.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDismissAlert = this.handleDismissAlert.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.editPartner && this.props.editPartner != null && prevProps.editPartner != this.props.editPartner) {
            console.log("partner", this.props.editPartner);
            this.setState({
                ...this.state,
                editedPartner: this.props.editPartner
            })
        }
    }
    setField(fieldName, data) {
        let editedPartner = this.state.editedPartner;
        editedPartner[fieldName] = data;
        this.setState({ editedPartner });
    }
    handleDismissAlert(){
        this.setState({
            alert :null,
        });
        this.props.closeEditForm()
    }
    handleUpdate(){
        this.setState({
            isFetching : true
        });
        const payload ={
              fuel_partner : {
                  name : this.state.editedPartner.name,
                  phone : this.state.editedPartner.phone,
                  address : this.state.editedPartner.address,
                  status : this.state.editedPartner.status,
                  email : this.state.editedPartner.email
              }
        }
        const url = `${API_URL}/manage/api/fuel_partners/${this.state.editedPartner.id}`;
        const response = ajax.put(url,
                                  payload,
                                  {
                                    'Content-Type': 'application/json',
                                    'Authorization': this.props.account.readToken()
                                });
        response.subscribe(
            res=>{
               this.setState({
                   isFetching:false,
                   alert : res.response.notice
               })
            },
            err=>{
                console.log(err);
            }
        )  
    }
    render() {
        return (
            <Modal
                open={this.props.openEditForm}
                closeOnEscape={true}
                closeOnDimmerClick={true}
                onClose={this.props.closeEditForm}
                centered={false}>
                <Modal.Header>Edit Partner</Modal.Header>
                <Modal.Content>
                   {
                       (this.state.alert!=null)?
                       <Alert handleDismiss={this.handleDismissAlert} message={this.state.alert}/>: <div></div>

                   }
                    <Form
                        className="attached fluid segment">
                        <Form.Field>
                            <label>Name</label>
                            <Input
                                placeholder="Partner Name"
                                value={(this.state.editedPartner) ? this.state.editedPartner.name : ''}
                                onChange={e => this.setField('name', e.target.value)} />
                        </Form.Field>
                        <Form.Field>
                            <label>Code</label>
                            <Input
                                placeholder="Partner Code"
                                value={(this.state.editedPartner) ? this.state.editedPartner.station_code : ''}
                                onChange={e => this.setField('station_code', e.target.value)} />
                        </Form.Field>
                        <Form.Field>
                            <label>Phone</label>
                            <Input
                                placeholder="Partner Phone"
                                value={(this.state.editedPartner) ? this.state.editedPartner.phone : ''}
                                onChange={e => this.setField('phone', e.target.value)} />
                        </Form.Field>
                        <Form.Field>
                            <label>Email</label>
                            <Input
                                placeholder="Partner Email"
                                autoComplete="email"
                                value={(this.state.editedPartner) ? this.state.editedPartner.email : ''}
                                onChange={e => this.setField('email', e.target.value)} />
                        </Form.Field>
                        <Form.Field>
                            <label>Address</label>
                            <TextArea
                                placeholder="Partner Address"
                                value={(this.state.editedPartner) ? ((this.state.editedPartner.address) ? this.state.editedPartner.address : '') : null}
                                onChange={e => this.setField('address', e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Status</label>
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label="activate"
                                name="statusGroup"
                                checked={((this.state.editedPartner) ? this.state.editedPartner.status === 'active' : false)}
                                onChange={_ => this.setField('status', 'active')}
                            />
                            <Radio
                                label="deactivate"
                                name="statusGroup"
                                checked={((this.state.editedPartner) ? this.state.editedPartner.status === 'inactive' : false)}
                                onChange={_ => this.setField('status', 'inactive')}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Button positive loading={this.state.loading} onClick={() => { this.handleUpdate() }} >Update</Button>
                            <Button secondary onClick={() => { this.props.closeEditForm() }}>Close</Button>
                        </Form.Field>
                    </Form>
                </Modal.Content>
            </Modal>
        );
    }
}

export default FuelPartnerModal;