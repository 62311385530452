import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'semantic-ui-react';

const LoginNavigationButton = ({ history }: any) => (
  <Button
    onClick={() => history.push('/login')}
    positive
  >OK</Button>
);

export default withRouter(LoginNavigationButton);
