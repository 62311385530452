import React, { PureComponent } from 'react';
import { Button, Container, Form, Header, Input, Radio, Select, TextArea,Message,Icon,Divider } from 'semantic-ui-react';
import { head, isEmpty, last, toPairs } from 'ramda';
import Account from '../../models/Account.ts';
import { ajax } from 'rxjs/ajax';
import split from 'ramda/es/split';
import history from '../../utils/history';
import { API_URL } from '../../constants/credentials';


class FuelStationCreation extends PureComponent{
   state={
     name: '',
     phone : '',
     location:'',
     address:'',
     station_code:'',
     prices:null,
     fuel_partner_id: '',
     status:'',
     username:'',
     password:'',
     city:'',
     error:'',
     stations:[],
     octane_92 :0,
     octane_95 : 0,
     diesel : 0,
     premium_diesel :0,
     isFetching : false
   };
   componentDidMount(){
    const response = ajax.get(`${API_URL}/manage/api/fuel_partners/lists`,
    { 
      'Content-Type': 'application/json',
      'Authorization': Account.readToken()
    });
    response.subscribe(
      res => { 
              let stationList =   toPairs(res.response);
              //console.log(stationList);
              this.setState({stations:stationList});
              },
      err => {
        console.log(err);
      },
    );
   }
    transform(data){
      const {name,phone,location,station_code,prices,fuel_partner_id,status,username,password,city} = data;
      const payload = {
        fuel_station :{
          name,
          phone,
          location,
          station_code,
          prices,
          fuel_partner_id,
          status,
          username,
          password,
          city
        }
      }
      return payload;
    }

    setField (fieldName, data) {
      let field = {};
      field[fieldName] = data;
  
      this.setState({ ...this.state, ...field });
    }
    parsePartnerNames (stations) {
      if (isEmpty(stations)) return [];
    
      return stations.map(station => ({
        key: head(station),
        value: head(station),
        text: last(station),
      }));
    };
    handleSubmit(){
      this.setState({
        isFetching:true
      });
      let payload = {
        fuel_station : {
          name: this.state.name,
          fuel_partner_id : this.state.fuel_partner_id,
          station_code : this.state.station_code,
          phone : this.state.phone,
          status :this.state.status,
          location : {
            address : this.state.address,
            location : split(this.state.location)
          },
          prices : {
            octane_92 : this.state.octane_92,
            octane_95 : this.state.octane_95,
            diesel : this.state.diesel,
            premium_diesel : this.state.premium_diesel
          },
          username : this.state.username,
          password : this.state.password,
          city : this.state.city
        }
      }
      console.log(payload);
      let response = ajax.post(`${API_URL}/manage/api/fuel_stations`,
                     payload,
                    {
                      'Content-Type': 'application/json',
                      'Authorization': Account.readToken()
                    });
          response.subscribe(res=>{
                              console.log(res);
                              this.setState({
                                isFetching : false
                              });
                              history.push('/index/fuel_stations')
                              },
                              err=>{
                                console.log("Error",err);
                              })          

    }
   render(){
     return (
     <Container>
     <Header as="h3">Create Fuel Station</Header>
     <Form className="attached fluid segment"
           onSubmit={e => {
             e.preventDefault();
             this.handleSubmit();
           }}
     >
       <Form.Group widths="equal">
         <Form.Field>
           <label>Station Name</label>
           <Input
             placeholder="Station Name"
             value={this.state.name}
             onChange={e => this.setField('name',e.target.value)}/>
         </Form.Field>
         <Form.Field>
           <label>Partner Name</label>
           <Select
             placeholder="--Select--"
             options={this.parsePartnerNames(this.state.stations)}
             onChange={(event, data) => this.setField('fuel_partner_id', data.value)}
           />
         </Form.Field>
       </Form.Group>
       <Form.Field>
         <label>Station Code</label>
         <Input
           placeholder="Station Code"
           value={this.state.code}
           onChange={e => this.setField('station_code',e.target.value)}/>
       </Form.Field>
       <Form.Field>
         <label>Address</label>
         <TextArea
           placeholder="Address"
           value={this.state.address}
           onChange={e => this.setField('address',e.target.value)}
         />
       </Form.Field>
       <Form.Field>
         <label>Location</label>
         <Input
           placeholder="Location"
           value={this.state.location}
           onChange={e => this.setField('location',e.target.value)}
         />
       </Form.Field>
       <Form.Field>
         <label>Phone</label>
         <Input
           type="tel"
           placeholder="Phone"
           value={this.state.phone}
           onChange={e => this.setField('phone',e.target.value)}
         />
       </Form.Field>
       <Form.Field>
         <Radio
           label="activate"
           name="statusGroup"
           checked={this.state.status === 'active'}
           value={this.state.status}
           onChange={e => this.setField('status','active')}
         />
         <Radio
           label="deactivate"
           name="statusGroup"
           checked={this.state.status === 'inactive'}
           value={this.state.status}
           onChange={e => this.setField('status','inactive')}
         />
       </Form.Field>
       <Divider hidden horizontal section>
            <Header as="h3">Prices</Header>
        </Divider>
        <Form.Field>
         <label>Octane 92 price</label>
        <Input
           placeholder="Octane 92"
           value={this.state.octane_92}
           type="number"
           onChange={e => this.setField('octane_92',e.target.value)}
         />
        </Form.Field> 
        <Form.Field>
         <label>Octane 95 price</label>
        <Input
           placeholder="Octane 95"
           value={this.state.octane_95}
           type="number"
           onChange={e => this.setField('octane_95',e.target.value)}
         />
        </Form.Field>
        <Form.Field>
         <label>Diesel price</label>
        <Input
           placeholder="Diesel"
           value={this.state.diesel}
           type="number"
           onChange={e => this.setField('diesel',e.target.value)}
         />
        </Form.Field>
        <Form.Field>
         <label>Premium Diesel Price</label>
        <Input
           placeholder="premium_diesel"
           value={this.state.premium_diesel}
           type="number"
           onChange={e => this.setField('premium_diesel',e.target.value)}
         />
        </Form.Field>
       <Divider hidden horizontal section>
            <Header as="h3">User</Header>
        </Divider>
        <Form.Field>
         <label>User name</label>
        <Input
           placeholder="User Name"
           value={this.state.username}
           autoComplete="username"
           onChange={e => this.setField('username',e.target.value)}
         />
         </Form.Field>
         <Form.Field>
         <label>Password</label>
         <Input
           placeholder="Password"
           value={this.state.password}
           type="password"
           autoComplete="new-password"
           onChange={e => this.setField('password',e.target.value)}
         />
         </Form.Field>
         <Form.Field>
         <label>City</label>
         <Input
           placeholder="City"
           value={this.state.city}
           type="text"
           onChange={e => this.setField('city',e.target.value)}
         />
         </Form.Field>
       <Form.Field>
         <Button positive loading={this.state.isFetching}>Create</Button>
       </Form.Field>
     </Form>
     <Message attached="bottom" error hidden={this.state.error === ''}>
         <Icon name="exclamation"/>
         {this.state.error}
       </Message>
   </Container>
   )
   }
}


export default FuelStationCreation;
