export const REQUEST_FUEL_STATIONS = 'REQUEST_FUEL_STATIONS';

export const RECEIVE_FUEL_STATIONS = 'RECEIVE_FUEL_STATIONS';

export const requestFuelStations = () => ({
  type: REQUEST_FUEL_STATIONS,
});

export const receiveFuelStations = data => ({
  type: RECEIVE_FUEL_STATIONS,
  data,
  receivedAt: Date.now(),
});
