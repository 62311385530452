import {
  REQUEST_FUEL_MANAGERS,
  RECEIVE_FUEL_MANAGERS,
} from '../actions/fuelManagerActions';

const initialState = {
  fetching: true,
  fuelManagers: [],
  receivedAt: null,
};

export const fuelManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FUEL_MANAGERS:
      return {
        ...state,
        fetching: true,
      };
    case RECEIVE_FUEL_MANAGERS:
      return {
        ...state,
        fuelManagers: action.data,
        fetching: false,
        receivedAt: action.receivedAt,
      };
    default:
      return state;
  }
};
