import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { Container, Grid, Menu } from 'semantic-ui-react';
import Account from './models/Account.ts';
import Badge from './components/Badge/index.tsx';
import FuelPartnerCreation from './components/FuelPartnerCreation';
import FuelPartnerList from './components/FuelPartnerList/index.tsx';
import FuelStationCreation from './components/FuelStationCreation';
import FuelStationList from './components/FuelStationList/index.tsx';
import FuelStations from './components/FuelStations';
import Login from './components/Login/index.tsx';
import Logout from './components/Logout';

const Home = () => <div>Home</div>;

const App = () =>
    <Container>
      <Grid>
        <Grid.Column width={4}>
          <Menu vertical>
            <Menu.Item name="Home" as={Link} to="/" />
            <Menu.Item name="Log In" as={Link} to="/login" />
            <Menu.Item name="List Fuel Partners" as={Link} to="/index/fuel_partners" />
            <Menu.Item name="List Fuel Stations" as={Link} to="/index/fuel_stations" />
            <Menu.Item name="Create Fuel Partner" as={Link} to="/create/fuel_partner" />
            <Menu.Item name="Create Fuel Station" as={Link} to="/create/fuel_station" />
            <Menu.Item name="Log Out" as={Link} to="/logout" />
          </Menu>
        </Grid.Column>
        <Grid.Column stretched width={12}>
          {
            Account.loggedIn() ?
              <Badge /> :
              null
          }
          <Switch>
            <Route exact path="/" component={Home} />
            <Route  path="/logout" component={Logout} />
            <Route  path="/login" component={Login} />
            <Route  path="/create/fuel_partner" component={FuelPartnerCreation} />
            <Route  path="/create/fuel_station" component={FuelStationCreation} />
            <Route  path="/index/fuel_partners" component={FuelPartnerList} />
            <Route  path="/index/fuel_stations" component={FuelStationList} />
            <Route  path="/manage/fuel_stations" component={FuelStations} />
          </Switch>


        </Grid.Column>
      </Grid>
    </Container>

export default App;
