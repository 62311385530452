import React, { Component } from 'react';
import { Modal, List, Icon,Button} from 'semantic-ui-react';
class FuelPartnerDetail extends Component {
    constructor(props){
        super(props);
    }
    render() {
        const {detailsPartner} = this.props;
        return (
            <Modal
                open={this.props.openDetail}
                closeOnEscape={true}
                closeOnDimmerClick={true}
                onClose={this.props.closeDetail}
                centered={false}>
                <Modal.Header>{(detailsPartner)?detailsPartner.name : ''}</Modal.Header>
                <Modal.Content>
                    <List divided relaxed>
                        <List.Item header="Partner Name" content={(detailsPartner)?detailsPartner.name : ''} 
                        />
                         <List.Item header="Balance" content={(detailsPartner)?detailsPartner.balance : 0} 
                        />
                        <List.Item header="Address" content={(detailsPartner)?detailsPartner.address : ''} 
                        />
                        <List.Item header="Station Count" content={(detailsPartner)?detailsPartner.fuel_stations.length : ''} 
                        />
                        <List.Item header="Phone" content={(detailsPartner)?detailsPartner.phone : ''} />

                         <List.Item header="Status" content={(detailsPartner)?detailsPartner.status : ''} 
                        />
                    </List>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={()=>{this.props.closeDetail()}}>Close</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default FuelPartnerDetail;