import { ajax } from 'rxjs/ajax';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import {
  receiveFuelStations,
  REQUEST_FUEL_STATIONS,
} from '../actions/fuelStationActions';
import {
  receiveFuelManagers,
  REQUEST_FUEL_MANAGERS,
} from '../actions/fuelManagerActions';

const GET_FUEL_STATIONS = 'http://beta.devoway.com/manage/api/fuel_stations';
const GET_FUEL_MANAGERS = 'http://beta.devoway.com/manage/api/fuel_managers';

const requestFuelStationsEpic = (action$, state$) =>
  action$.pipe(
    ofType(REQUEST_FUEL_STATIONS),
    switchMap(() =>
      ajax
        .get(GET_FUEL_STATIONS, { 'Content-Type': 'application/json' })
        .pipe(map(response => receiveFuelStations(response.response)))
    )
  );

const requestFuelManagersEpic = (action$, state$) =>
  action$.pipe(
    ofType(REQUEST_FUEL_MANAGERS),
    switchMap(() =>
      ajax
        .get(GET_FUEL_MANAGERS, { 'Content-Type': 'application/json' })
        .pipe(map(response => receiveFuelManagers(response.response)))
    )
  );

export const rootEpic = combineEpics(
  requestFuelStationsEpic,
  requestFuelManagersEpic
);
