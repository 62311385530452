import React, { PureComponent } from 'react';
import { Button, Container, Form, Header, Input } from 'semantic-ui-react';
import { ajax } from 'rxjs/ajax';

import LoginInfo from '../../models/LoginInfo';
import history from '../../utils/history';
import { API_URL } from '../../constants/credentials';
import Error from '../Error';

type JSONHeader = { 'Content-Type': string }

const endpoint: string = `${API_URL}/sessions/managers`;
const headers: JSONHeader = { 'Content-Type': 'application/json' };

class Login extends PureComponent {
  
  state = {
    username: '',
    password: '',
    error:''
  };

  constructor(props : any){
    super(props);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleSuccess({ response }: any) {
    LoginInfo.store(response);
    history.push('index/fuel_stations');
  }
  handleDismiss() {
    this.setState({
      error: ''
    });
  }

  sendData() {
    const response = ajax.post(endpoint, this.state, headers);

    response.subscribe(
      this.handleSuccess,
      err=>{
        console.log(err);
        if(err.response){
           this.setState({
             error : err.response.error
           })
        }else{
          this.setState({
            error : err.message
          })
        }
      },
    );
  }

  render() {
    return <Container>
      <Header as="h3">Log In</Header>
      {
        (this.state.error !='')?
        <Error handleDismiss ={this.handleDismiss} message={this.state.error}/>:
        <div></div>
      }
      <Form
        className="attached fluid segment"
        onSubmit={e => {
          e.preventDefault();
          this.sendData();
        }}
      >
        <Form.Field>
          <label>Username</label>
          <Input
            placeholder="Username"
            onChange={e => this.setState({ ...this.state, username: e.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <Input
            type="password"
            placeholder="Password"
            onChange={e => this.setState({ ...this.state, password: e.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <Button positive>Sign In</Button>
        </Form.Field>
      </Form>
    </Container>;
  }
}

export default Login;