export const REQUEST_FUEL_MANAGERS = 'REQUEST_FUEL_MANAGERS';

export const RECEIVE_FUEL_MANAGERS = 'RECEIVE_FUEL_MANAGERS';

export const requestFuelManagers = () => ({
  type: REQUEST_FUEL_MANAGERS,
});

export const receiveFuelManagers = data => ({
  type: RECEIVE_FUEL_MANAGERS,
  data,
  receivedAt: Date.now(),
});
