import React, { Component } from 'react';
import {Modal,Form,Input,Radio,Select,TextArea,Button,Dropdown} from 'semantic-ui-react';
import { ajax } from 'rxjs/ajax';
import { isEmpty,toPairs,head,last } from 'ramda';
import Alert from '../Alert';
import Error from '../Error';
import { API_URL } from '../../constants/credentials';

class fuelStationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stations:[],
            editedStation:null,
            isFetching:false,
            alert : null,
            error : null
        }
        this.setField = this.setField.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleDismissAlert = this.handleDismissAlert.bind(this);
        this.handleDismissError = this.handleDismissError.bind(this);
    }
    componentDidMount() {
        const response = ajax.get(`${API_URL}/manage/api/fuel_partners/lists`,
        { 
          'Content-Type': 'application/json',
          'Authorization': this.props.account.readToken()
        });
        response.subscribe(
          res => { 
                  let stationList =   toPairs(res.response);
                  //console.log(stationList);
                  this.setState({stations:stationList});
                  },
          err => {  
            console.log(err);
          },
        );
    }
    componentDidUpdate(prevProps, prevState) {
        if(this.props.editStation && this.props.editStation!=null && prevProps.editStation != this.props.editStation ){
            this.setState({
                ...this.state,
                editedStation : this.props.editStation
            })
        }
    }
    handleUpdate(){
        this.setState({
            isFetching : true
        });
        const payload ={
              fuel_station : {
                  name : this.state.editedStation.name,
                  phone : this.state.editedStation.phone,
                  address : this.state.editedStation.address,
                  status : this.state.editedStation.status,
                  fuel_partner_id : this.state.editedStation.fuel_partner_id
              }
        }
        const url = `${API_URL}/manage/api/fuel_stations/${this.state.editedStation.id}`;
        const response = ajax.put(url,
                                  payload,
                                  {
                                    'Content-Type': 'application/json',
                                    'Authorization': this.props.account.readToken()
                                });
        response.subscribe(
            res=>{
               console.log(res.response);
               this.setState({
                   isFetching:false,
                   alert : res.response.notice
               }) ;
               //this.props.closeEditForm();
            },
            err=>{
                console.log(err);
            }
        )                        


    }
    setField (fieldName, data) {
        let editedStation= this.state.editedStation;
         editedStation[fieldName] = data; 
        this.setState({editedStation});
      }
    setLocation(fieldName,data){
        let location = this.state.editedStation.location;
        location[fieldName] = data;
        this.setState({location});
    }  
    parsePartnerNames () {
        console.log("Id",(this.props.editStation)?this.props.editStation.fuel_partner_id:"undefined");
        if (isEmpty(this.state.stations)) return [];
      
        return this.state.stations.map(station => {
        if(this.props.editStation && station.name === this.props.editStation.fuel_partner_name){
            this.setState({
                fuel_partner_id : head(station)
            })
        }    
        return {
          key: head(station),
          value: head(station),
          text: last(station),
        }
        }
        );
      };
      handleDismissAlert(){
          this.setState({
              alert :null,
          });
          this.props.closeEditForm()
      }
      handleDismissError(){
          this.setState({
              error:null
          })
      }

    render() {
        return (
            <Modal
                open={this.props.openEditForm}
                closeOnEscape={true}
                closeOnDimmerClick={true}
                onClose={this.closeEditForm}
                centered={false}>
                <Modal.Header>Edit Fuel Station List</Modal.Header>
                <Modal.Content>
                    {
                        (this.state.alert!==null)?
                        <Alert handleDismiss={this.handleDismissAlert} message={this.state.alert}/>:<div></div>
                        


                    }
                    {
                        (this.state.error !==null)?
                        <Error handleDismiss={this.handleDismissError} message={this.state.err}/> : <div></div>
                    }
                    <Form className="attached fluid segment"
                        onSubmit={e => {
                            e.preventDefault();
                        }}
                    >
                        <Form.Group widths="equal">
                            <Form.Field>
                                <label>Station Name</label>
                                <Input
                                    value={(this.state.editedStation) ? this.state.editedStation.name : ''}
                                    onChange={e => this.setField('name',e.target.value)}
                                    placeholder="Station Name" />
                            </Form.Field>
                            <Form.Field>
                                <label>Partner Name</label>
                                {/* <Select
                                    placeholder="--Select--"
                                    options={this.parsePartnerNames()}
                                    value={(this.state.editStation) ?this.state.editStation.fuel_partner_id :null}
                                /> */}
                                   <Dropdown clearable options={this.parsePartnerNames()} selection  
                                   onChange={(e,data) => this.setField('fuel_partner_id',data.value)}/>       
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <label>Station Code</label>
                            <Input
                                value={(this.state.editedStation) ? this.state.editedStation.station_code : ''}
                                onChange={e => this.setField('station_code',e.target.value)}
                                placeholder="Station Code" />
                        </Form.Field>
                        <Form.Field>
                            <label>Address</label>
                            <TextArea
                                value={(this.state.editedStation) ? this.state.editedStation.address : ''
                                }
                                onChange={e => this.setField('address',e.target.value)}
                                placeholder="Address"
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Location</label>
                            <TextArea
                                value={(this.state.editedStation &&this.state.editedStation.location.location ) ? this.state.editedStation.location.location.toString(): ''
                                }
                                onChange={e => this.setLocation('location',e.target.value)}
                                placeholder="location"
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Phone</label>
                            <Input
                                type="tel"
                                value={(this.state.editedStation) ? this.state.editedStation.phone : ''}
                                placeholder="Phone"
                                onChange={e => this.setField('phone',e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Radio
                                label="activate"
                                name="statusGroup"
                                checked={(this.state.editedStation) ? ((this.state.editedStation.status === "active") ? true : false) : false}
                                onChange={e => this.setField('status',e.target.value)}
                            />
                            <Radio
                                label="deactivate"
                                name="statusGroup"
                                checked={(this.state.editedStation) ? ((this.state.editedStation.status === "inactive") ? true : false) : false}
                                onChange={e => this.setField('status',e.target.value)}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary loading={this.state.isFetching} onClick={()=>{this.handleUpdate()} }>Update</Button>
                    <Button secondary onClick={() => { this.props.closeEditForm() }}>Close</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default fuelStationModal;