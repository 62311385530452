import React, { PureComponent } from 'react';
import {
  Button,
  Container, Divider,
  Form,
  Header,
  Icon,
  Input,
  Message,
  Radio,
  TextArea,
} from 'semantic-ui-react';
import { ajax } from 'rxjs/ajax';
import Account from '../../models/Account';
import history from '../../utils/history';
import { API_URL } from '../../constants/credentials';

class FuelPartnerCreation extends PureComponent {
  state = {
    loading: false,
    error: '',
    name: '',
    code: '',
    phone: '',
    email: '',
    address: '',
    managerName: '',
    managerPhone: '',
    managerEmail: '',
    password: '',
    status: 'active',
    managerStatus: 'active',
  };

  setField (fieldName, data) {
    let field = {};
    field[fieldName] = data;

    this.setState({ ...this.state, ...field });
  }

  transform (data) {
    const { name, code, phone, email, address, managerName, managerPhone, managerEmail, password, status, managerStatus } = data;
    const payload = {
      fuel_partner: {
        name, phone, email, address,
        station_code: code,
        status: managerStatus,
      },
      fuel_manager: {
        name: managerName,
        email: managerEmail,
        phone: managerPhone,
        password,
        status,
      },
    };

    return payload;
  }

  setError (error = '') {
    this.setState({ ...this.state, error });
  }

  setLoadingState (loading) {
    this.setState({ ...this.state, loading });
  }

  sendData () {
    const body = this.transform(this.state);

    this.setState({ ...this.state, loading: true, error: '' });

    const response = ajax.post(`${API_URL}/manage/api/fuel_partners/fuel_partner_manager_setup`, body, { 
      'Content-Type': 'application/json',
      'Authorization': Account.readToken()});

    response.subscribe(
      res => { this.setLoadingState(false); 
                history.push('/index/fuel_partners');
              },
      err => {
        console.log(err);
        this.setLoadingState(false);
        this.setError((err.response)?err.response.error:err.message);
      },
    );
  }

  render () {
    return <div>
      <Container>
        <Header as="h3">Create Fuel Partner</Header>
        <Form
          className="attached fluid segment"
          onSubmit={e => {
            e.preventDefault();
            this.sendData();
          }}
        >
          <Divider hidden horizontal section>
            <Header as="h3">Partner</Header>
          </Divider>
          <Form.Field>
            <label>Name</label>
            <Input
              placeholder="Partner Name"
              value={this.state.name}
              onChange={e => this.setField('name', e.target.value)}/>
          </Form.Field>
          <Form.Field>
            <label>Code</label>
            <Input
              placeholder="Partner Code"
              value={this.state.code}
              onChange={e => this.setField('code', e.target.value)}/>
          </Form.Field>
          <Form.Field>
            <label>Phone</label>
            <Input
              placeholder="Partner Phone"
              value={this.state.phone}
              onChange={e => this.setField('phone', e.target.value)}/>
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <Input
              placeholder="Partner Email"
              value={this.state.email}
              onChange={e => this.setField('email', e.target.value)}/>
          </Form.Field>
          <Form.Field>
            <label>Address</label>
            <TextArea
              placeholder="Partner Address"
              value={this.state.address}
              onChange={e => this.setField('address', e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Status</label>
          </Form.Field>
          <Form.Field>
            <Radio
              label="activate"
              name="statusGroup"
              checked={this.state.status === 'active'}
              value={this.state.status}
              onChange={_ => this.setField('status', 'active')}
            />
            <Radio
              label="deactivate"
              name="statusGroup"
              checked={this.state.status === 'inactive'}
              value={this.state.status}
              onChange={_ => this.setField('status', 'inactive')}
            />
          </Form.Field>
          <Divider hidden horizontal section>
            <Header as="h3">Manager</Header>
          </Divider>
          <Form.Field>
            <label>Name</label>
            <Input
              placeholder=" Manager Name"
              value={this.state.managerName}
              onChange={e => this.setField('managerName', e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <Input
              placeholder=" Manager Email"
              value={this.state.managerEmail}
              onChange={e => this.setField('managerEmail', e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Phone</label>
            <Input
              placeholder=" Manager Phone"
              value={this.state.managerPhone}
              onChange={e => this.setField('managerPhone', e.target.value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <Input
              type="password"
              placeholder="Manager Password"
              value={this.state.password}
              onChange={e => this.setField('password', e.target.value)}/>
          </Form.Field>
          <Form.Field>
            <label>Status</label>
          </Form.Field>
          <Form.Field>
            <Radio
              label="activate"
              name="managerStatusGroup"
              checked={this.state.managerStatus === 'active'}
              value={this.state.managerStatus}
              onChange={_ => this.setField('managerStatus', 'active')}
            />
            <Radio
              label="deactivate"
              name="managerStatusGroup"
              checked={this.state.managerStatus === 'inactive'}
              value={this.state.managerStatus}
              onChange={_ => this.setField('managerStatus', 'inactive')}
            />
          </Form.Field>
          <Form.Field>
            <Button positive loading={this.state.loading}>Create</Button>
          </Form.Field>
        </Form>
        <Message attached="bottom" error hidden={this.state.error === ''}>
          <Icon name="exclamation"/>
          {this.state.error}
        </Message>
      </Container>
    </div>;
  }
}

export default FuelPartnerCreation;
