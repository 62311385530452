import React, { PureComponent } from 'react';

import LoginInfo from '../../models/LoginInfo';

class Logout extends PureComponent {
  componentDidMount () {
    LoginInfo.delete();
  }

  render () {
    return <div>You've been logged out.</div>;
  }
}

export default Logout;
