import React, { Component } from 'react';
import { Message } from 'semantic-ui-react'

class Alert extends Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <Message
                onDismiss={this.props.handleDismiss}
                positive>
                <Message.Header>Success</Message.Header>
                <p>{this.props.message}</p>
            </Message>
        );
    }
}


export default Alert;