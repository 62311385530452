import React from 'react';
import { Label } from 'semantic-ui-react';

import Account from '../../models/Account';
import { getName, getRoleName } from '../../helpers/account';

const Badge = () =>
  <div>
    <Label as='a' color='blue'>
      {getName(Account.readAccount())}
      <Label.Detail>{getRoleName(Account.readAccount())}</Label.Detail>
    </Label>
  </div>;

export default Badge;