import React, { PureComponent } from 'react';
import { Button, Container, Header, Modal, Pagination, Table } from 'semantic-ui-react';
import { ajax } from 'rxjs/ajax';
import { isNil } from 'ramda';
import Account from '../../models/Account';
import LoginNavigationButton from '../LoginNavigationButton/index';
import Spinner from '../../components/Spinner';
import Error from '../../components/Error';
import FuelStationModal from './fuelStationModal.js'
import FuelStationDetail from './fuelStationDetail.js';
import {API_URL} from '../../constants/credentials';

type JsonHeader = {
  'Content-Type': string,
  Authorization: null | string
}

type FuelStationInfo = {
  id: number,
  fuel_partner_name: string,
  station_code: string,
  name: string,
  current_balance: number,
  inactive: number,
  status: string,
}

type FuelStationListState = {
  data: null | Array<FuelStationInfo>,
  meta: null | FuelStationMeta,
  open: boolean,
  openEditForm: boolean,
  isFetching: boolean,
  selectOptions: any,
  activePage: number,
  editStation: any,
  openDetail : boolean,
  error: any,
  detailsStation :any

}

type FuelStationMeta = {
  total: number,
  per_page: number
}

type Error = {
  status: number
}

const url: string = `${API_URL}/manage/api/fuel_stations?page=1`;

class FuelStationList extends PureComponent<any, any> {
  state: FuelStationListState = {
    data: null,
    meta: null,
    open: false,
    openEditForm: false,
    openDetail : false,
    selectOptions: [
      { key: 1, value: "Z1Z", text: "RPG" },
      { key: 2, value: "Z2Z", text: "GPG" },
      { key: 3, value: "Z3Z", text: "cPG" },
      { key: 4, value: "Z4Z", text: "zPG" },
      { key: 5, value: "Z5Z", text: "xPG" }
    ],
    isFetching: false,
    activePage: 1,
    editStation: null,
    detailsStation:null,
    error: ''
  };

  constructor(props: any) {
    super(props);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
    this.close = this.close.bind(this);
    this.closeEditForm = this.closeEditForm.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleSuccess({ response }: any) {
    console.log("Response", response);
    this.setState({ ...this.state, data: response.data, meta: response.meta, isFetching: false });
  }

  handleError(error: any) {
    console.log("Error", error);
    this.setState({
      error: error.message,
      isFetching: false
    })
  }

  componentDidMount() {
    console.log("token", Account.readToken());
    const headers: JsonHeader = {
      'Content-Type': 'application/json',
      Authorization: Account.readToken(),
    };
    this.setState({
      isFetching: true
    })
    ajax({ url, headers })
      .subscribe(this.handleSuccess, err => this.handleError(err));
  }
  handleDismiss() {
    this.setState({
      error: ''
    });
  }

  close() {
    this.setState({ ...this.state, close: true });
  }
  handlePaginationChange(activePage: any) {
    console.log("Pagination Change", activePage);
    this.setState({ activePage });
    let url = `${API_URL}/manage/api/fuel_stations?page=${activePage}`
    const headers: JsonHeader = {
      'Content-Type': 'application/json',
      Authorization: Account.readToken(),
    };
    this.setState({ isFetching: true });
    ajax({ url, headers })
      .subscribe(this.handleSuccess, console.error);
  }
  closeEditForm() {
    this.setState({ openEditForm: false });
  }

  render() {
    if (this.state.isFetching)
      return <Spinner />
    else
      return <Container>
        <Header as="h3">Fuel Station List</Header>
        {
          (this.state.error === '') ?
            <Table singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Partner Name</Table.HeaderCell>
                  <Table.HeaderCell>Station Code</Table.HeaderCell>
                  <Table.HeaderCell>Station Name</Table.HeaderCell>
                  <Table.HeaderCell>Balance</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!isNil(this.state.data) ? this.state.data.map((row: FuelStationInfo) => <Table.Row key={row.id}>
                  <Table.Cell>{(!!row.fuel_partner_name)?row.fuel_partner_name:'-' }</Table.Cell>
                  <Table.Cell>{row.station_code}</Table.Cell>
                  <Table.Cell>{row.name}</Table.Cell>
                  <Table.Cell>{row.current_balance}</Table.Cell>
                  <Table.Cell>{row.status}</Table.Cell>
                  <Table.Cell>
                    <Button secondary onClick={() => { this.setState({ openEditForm: true, editStation: row }) }}>Edit</Button>
                    <Button positive onClick={()=>{this.setState({openDetail:true,detailsStation:row})}}>Detail</Button>
                    </Table.Cell>
                </Table.Row>) : null}
              </Table.Body>
            </Table> :
            <Error handleDismiss={this.handleDismiss} message={this.state.error} />
        }

        {
          !isNil(this.state.meta) ?
            <Pagination
              boundaryRange={this.state.meta.per_page}
              defaultActivePage={this.state.activePage}
              onPageChange={(e, { activePage }) => this.handlePaginationChange(activePage)}
              totalPages={Math.ceil(this.state.meta.total / this.state.meta.per_page)}
            /> :
            null
        }
        <Modal
          open={this.state.open}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          onClose={this.close}
        >
          <Modal.Header>You need to log in</Modal.Header>
          <Modal.Content>
            <p>You are not logged in. You will be redirected to the log in page.</p>
          </Modal.Content>
          <Modal.Actions>
            <LoginNavigationButton />
          </Modal.Actions>
        </Modal>
        <FuelStationModal editStation={this.state.editStation}
          openEditForm={this.state.openEditForm}
          closeEditForm={() => { this.setState({ openEditForm: false }) }}
          account={Account} />
        <FuelStationDetail 
          openDetail={this.state.openDetail} 
          detailsStation={this.state.detailsStation}
          closeDetail = {()=>{this.setState({openDetail:false})}}
          />
      </Container>;

  }
}

export default FuelStationList;
