import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';

import loggerMiddleware from './middlewares/logger';
import monitorReducersEnhancer from './enhancers/monitorReducers';
import { rootEpic } from './epics';
import rootReducer from './reducers';

const configureStrore = preloadedState => {
  const epicMiddleware = createEpicMiddleware();

  const middlewares = [epicMiddleware, loggerMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, monitorReducersEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  epicMiddleware.run(rootEpic);

  return store;
};

export default configureStrore;
