import React from 'react'
import { Message } from 'semantic-ui-react'


const Error = (props) =>
    <Message
        onDismiss={props.handleDismiss}
        negative>
        <Message.Header>Error</Message.Header>
        <p>{props.message}</p>
    </Message>

export default Error;