class Account {
  static readAccount(): Object | null {
    const account: string | null = localStorage.getItem('account');

    return account ? JSON.parse(account) : null;
  }

  static writeAccount(account: Object): void {
    localStorage.removeItem('account');
    localStorage.setItem('account', JSON.stringify(account));
  }

  static readToken(): string | null {
    const account: string | null = localStorage.getItem('account');

    return account ? JSON.parse(account).sso_token : null;
  }

  static loggedIn(): boolean {
    return localStorage.getItem('account') !== null;
  }
}

export default Account;