class LoginInfo {
  constructor (id, name, phone, ssoToken, username) {
    this.id = id;
    this.name = name;
    this.phone = phone;
    this.sso_token = ssoToken;
    this.username = username;
  }

  _serialize () {
    return JSON.stringify({
      id: this.id,
      name: this.name,
      phone: this.phone,
      sso_token: this.sso_token,
      username: this.username,
    });
  }

  save () {
    localStorage.setItem('fuel.account', this._serialize());
  }

  static delete () {
    localStorage.removeItem('account');
  }

  static store (data) {
    localStorage.setItem('account', JSON.stringify(data));
  }

  static getToken () {
    const loginInfo = localStorage.getItem('manager.account');

    return loginInfo ?
      JSON.parse(loginInfo).sso_token : null;
  }
}

export default LoginInfo;