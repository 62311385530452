import { combineReducers } from 'redux';

import { fuelStationsReducer } from './fuelStationsReducer';
import { fuelManagerReducer } from './fuelManagerReducer';
import { connectRouter } from 'connected-react-router';
import history from '../utils/history';

const rootReducer = combineReducers({ 
    fuelStationReducer: fuelStationsReducer, 
    fuelManagerReducer: fuelManagerReducer, 
    router : connectRouter(history)
});
    

export default rootReducer;
