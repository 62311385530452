import {
  REQUEST_FUEL_STATIONS,
  RECEIVE_FUEL_STATIONS,
} from '../actions/fuelStationActions';

const initialState = {
  fetching: true,
  fuelStations: [],
  receivedAt: null,
};

export const fuelStationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FUEL_STATIONS:
      return {
        ...state,
        fetching: true,
      };
    case RECEIVE_FUEL_STATIONS:
      return {
        ...state,
        fuelStations: action.data,
        fetching: false,
        receivedAt: action.receivedAt,
      };
    default:
      return state;
  }
};
