import React, { PureComponent } from 'react';
import {
  Button,
  Container,
  Form,
  Select,
  Divider,
  Table,
} from 'semantic-ui-react';
import { ajax } from 'rxjs/ajax';
import { connect } from 'react-redux';

import { requestFuelStations } from '../../actions/fuelStationActions';
import { requestFuelManagers } from '../../actions/fuelManagerActions';

const buildURL = id => `http://beta.devoway.com/manage/api/fuel_managers/${id}`;
const buildStationURL = id =>
  `http://beta.devoway.com/manage/api/fuel_stations/${id}`;

class FuelStations extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedManager: {
        fuel_stations: [],
      },
      markedManager: null,
      markdStation: null,
    };
  }

  componentDidMount() {
    this.props.requestFuelStations();
    this.props.requestFuelManagers();
  }

  fetchFuelStationsByFuelManager(id) {
    if (id === null) return;

    const response = ajax({
      url: buildURL(id),
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    response.subscribe(res => this.success(res), err => this.fail(err));
  }

  success(res) {
    this.setState({
      ...this.state,
      selectedManager: res.response.resource,
    });
  }

  fail(err) {
    console.error(err);
  }

  updateSuccess(res) {
    console.log(res);
  }

  updateFail(err) {
    console.error(err);
  }

  markManager(markedManager) {
    this.setState({ ...this.state, markedManager }, () =>
      this.fetchFuelStationsByFuelManager(this.state.markedManager)
    );
  }

  markStation(markedStation) {
    this.setState({ ...this.state, markedStation });
  }

  updateStation(id) {
    if (id == null || this.state.markedManager == null) return;

    const body = {
      fuel_manager_id: this.state.markedManager,
    };

    const response = ajax({
      url: buildStationURL(id),
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body,
    });

    response.subscribe(
      res => this.updateSuccess(res),
      err => this.updateFail(err)
    );
  }

  render() {
    return (
      <Container>
        <h1>Manage Fuel Station</h1>
        <Form className="attached fluid segment">
          <Form.Field>
            <label>Fuel Companies</label>
            <Select
              placeholder="--Select--"
              options={parseFuelManagerOptions(
                this.props.fuelManagers.fuelManagers
              )}
              onChange={(event, data) => this.markManager(data.value)}
            ></Select>
          </Form.Field>
          <Form.Field>
            <label>Fuel Stations</label>
            <Select
              placeholder="--Select--"
              options={parseFuelStationOptions(
                this.props.fuelStations.fuelStations
              )}
              onChange={(event, data) => this.markStation(data.value)}
            ></Select>
          </Form.Field>
          <Form.Field>
            <Button
              positive
              onClick={() => this.updateStation(this.state.markedStation)}
            >
              Assign
            </Button>
          </Form.Field>
        </Form>
        <Divider />
        <Table unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Fuel Station ID</Table.HeaderCell>
              <Table.HeaderCell>Fuel Station Name</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
            {this.state.selectedManager.fuel_stations.map(station => (
              <Table.Row>
                <Table.Cell>{station.id}</Table.Cell>
                <Table.Cell>{station.name}</Table.Cell>
                <Table.Cell>
                  <Button negative>Delete</Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Header>
        </Table>
      </Container>
    );
  }
}

const parseFuelStationOptions = stations =>
  stations.map(station => ({
    key: station.id,
    value: station.id,
    text: station.name,
  }));

const parseFuelManagerOptions = managers =>
  managers.map(manager => ({
    key: manager.id,
    value: manager.id,
    text: manager.name,
  }));

const mapState = state => ({
  fuelStations: state.fuelStationReducer,
  fuelManagers: state.fuelManagerReducer,
});

const mapDispatch = dispatch => ({
  requestFuelStations: () => dispatch(requestFuelStations()),
  requestFuelManagers: () => dispatch(requestFuelManagers()),
});

export default connect(
  mapState,
  mapDispatch
)(FuelStations);
