import React, { Component } from 'react';
import { Modal, List, Icon,Button} from 'semantic-ui-react';



class fuelStationDetail extends Component {
    constructor(props){
        super(props);
    }
    render() {
        const {detailsStation} = this.props;
        return (
            <Modal
                open={this.props.openDetail}
                closeOnEscape={true}
                closeOnDimmerClick={true}
                onClose={this.props.closeDetail}
                centered={false}>
                <Modal.Header>{(detailsStation)?detailsStation.name : ''}</Modal.Header>
                <Modal.Content>
                    <List divided relaxed>
                        <List.Item header="Station Name" content={(detailsStation)?detailsStation.name : ''} 
                        />
                        <List.Item header="Partner Name" content={(detailsStation)?detailsStation.name : ''} 
                        />
                        <List.Item header="Station Code" content={(detailsStation)?detailsStation.station_code : ''}/>    
                        <List.Item header="Phone" content={(detailsStation)?
                        detailsStation.phone : ''} />
                        <List.Item header="City" content={(detailsStation)?
                        detailsStation.city : ''} />
                        <List.Item header="Octane 92" content={(detailsStation)?
                        (detailsStation.prices)?detailsStation.prices.octane_92+ ' MMK':'N/A'
                         : 'N/A'} />
                        <List.Item header="Octance 95" content={(detailsStation)?
                        (detailsStation.prices)?detailsStation.prices.octane_95+ ' MMK':'N/A'
                         : 'N/A'} />
                        <List.Item header="Diesel" content={(detailsStation)?
                        (detailsStation.prices)?detailsStation.prices.diesel+ ' MMK':'N/A'
                         : 'N/A'} />
                        <List.Item header="Premium Diesel" content={(detailsStation)?
                        (detailsStation.prices)?detailsStation.prices.premium_diesel+ ' MMK':'N/A'
                         : 'N/A'} />
                        <List.Item header="Status" content={(detailsStation)?
                        detailsStation.status : ''} />
                    </List>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={()=>{this.props.closeDetail()}}>Close</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default fuelStationDetail;