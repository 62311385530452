import React, { PureComponent } from 'react';
import { Button, Container, Header, Pagination, Table } from 'semantic-ui-react';
import { ajax } from 'rxjs/ajax';
import { isNil } from 'ramda';
import Spinner from '../../components/Spinner';
import Error from '../../components/Error';
import FuelPartnerModal from './FuelPartnerModal.js'
import FuelPartnerDetail from './FuelPartnerDetail';


import Account from '../../models/Account';
import { API_URL } from '../../constants/credentials';

type JsonHeader = {
  'Content-Type': string,
  Authorization: string | null
}

const url: string = `${API_URL}/manage/api/fuel_partners`;

type FuelPartnerListState = {
  activePage: number,
  data: null | Array<FuelPartnerInfo>,
  meta: null | FuelPartnerMeta,
  isFetching: boolean,
  error: string,
  editPartner: any,
  openEditForm: boolean,
  openDetail : boolean,
  detailsPartner : any, 
}

type FuelPartnerInfo = {
  id: number,
  name: string,
  station_code: string,
  phone: string,
  balance: number,
  inactive: number,
  fuel_stations: Array<any>,

}

type FuelPartnerMeta = {
  total: number,
  per_page: number
}

class FuelPartnerList extends PureComponent<any, any> {
  state: FuelPartnerListState = {
    activePage: 1,
    data: null,
    meta: null,
    isFetching: false,
    error: '',
    editPartner: null,
    openEditForm: false,
    detailsPartner :null,
    openDetail :false
  };

  constructor(props: any) {
    super(props);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handlePaginationChange(activePage: any) {
    console.log("Pagination Change", activePage);
    this.setState({ activePage });
    let url = `${API_URL}/manage/api/fuel_partners?page=${activePage}`
    const headers: JsonHeader = {
      'Content-Type': 'application/json',
      Authorization: Account.readToken(),
    };
    this.setState({ isFetching: true });
    ajax({ url, headers })
      .subscribe(this.handleSuccess, this.handleError);
  }
  handleSuccess({ response }: any) {
    console.log("Response", response);
    this.setState({ ...this.state, data: response.data, meta: response.meta, isFetching: false });
  }
  closeEditForm() {
    this.setState({ openEditForm: false });
  }
  handleError(error: any) {
    console.log("Error", error);
    this.setState({
      error: error.message,
      isFetching: false
    })
  }
  handleDismiss() {
    this.setState({
      error: ''
    });
  }

  componentDidMount() {
    const headers: JsonHeader = {
      'Content-Type': 'application/json',
      Authorization: Account.readToken(),
    };
    this.setState({ isFetching: true });
    ajax({ url, headers })
      .subscribe(this.handleSuccess, err => this.handleError(err));
  }

  render() {
    if (this.state.isFetching) {
      return <Spinner />
    } else {
      return <Container>
        <Header as="h3">Fuel Partner List</Header>
        {
          (this.state.error === '') ?
            <Table singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Code</Table.HeaderCell>
                  <Table.HeaderCell>Phone</Table.HeaderCell>
                  <Table.HeaderCell>Balance</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Station Count</Table.HeaderCell>
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {!isNil(this.state.data) ? this.state.data.map((row: FuelPartnerInfo) => <Table.Row key={row.id}>
                  <Table.Cell>{row.name}</Table.Cell>
                  <Table.Cell>{row.station_code}</Table.Cell>
                  <Table.Cell>{row.phone}</Table.Cell>
                  <Table.Cell>{row.balance}</Table.Cell>
                  <Table.Cell>{row.inactive === 1 ? 'inactive' : 'active'}</Table.Cell>
                  <Table.Cell>{row.fuel_stations.length}</Table.Cell>
                  <Table.Cell>
                    <Button secondary onClick={() => { this.setState({ openEditForm: true, editPartner: row }) }}>Edit</Button>
                    <Button positive onClick={() => { this.setState({ openDetail: true, detailsPartner: row }) }}>Detail</Button>
                  </Table.Cell>
                </Table.Row>) : null}
              </Table.Body>
            </Table> :
            <Error handleDismiss={this.handleDismiss} message={this.state.error} />
        }

        {
          !isNil(this.state.meta) ?
            <Pagination
              boundaryRange={this.state.meta.per_page}
              defaultActivePage={this.state.activePage}
              totalPages={Math.ceil(this.state.meta.total / this.state.meta.per_page)}
              onPageChange={(e, { activePage }) => this.handlePaginationChange(activePage)}
            /> :
            null
        }
        <FuelPartnerModal editPartner={this.state.editPartner}
          openEditForm={this.state.openEditForm}
          closeEditForm={() => { this.setState({ openEditForm: false }) }}
          account={Account} />
        <FuelPartnerDetail detailsPartner={this.state.detailsPartner} 
          openDetail = {this.state.openDetail}
          closeDetail = {()=>{this.setState({openDetail:false})}}
         /> 

      </Container>;
    }

  }
}

export default FuelPartnerList;